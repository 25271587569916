<footer class="footer_area h_footer_dark">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="f_widget dark_widget company_widget">
          <a href="/" class="f-logo"><img src="./assets/img/TRick-logo.png" alt=""></a>
          <p>
            Empower Circular Economy<br>
            With Blockchain Data Traceability
          </p>
        </div>
      </div>
      <div class="col-lg-2 col-md-6">
        <div class="f_widget dark_widget about-widget pl_70">
          <h6 class="f-title f_500 t_color f_size_18 mb_40">Project</h6>
          <ul class="list-unstyled f_list">
            <li><a href="https://www.trick-project.eu/about">About</a></li>
            <li><a href="https://www.trick-project.eu/pilots">Pilots</a></li>
            <li><a href="https://www.trick-project.eu/project-documents">Documents</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-2 col-md-6">
        <div class="f_widget dark_widget about-widget pl_20">
          <h6 class="f-title f_500 t_color f_size_18 mb_40">Info</h6>
          <ul class="list-unstyled f_list">
            <li><a href="https://www.trick-project.eu/contacts">Contact</a></li>
            <li><a href="http://eepurl.com/hGXTa5">Newsletter</a></li>
            <li><a href="https://www.trick-project.eu/news">News & Activities</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="f_widget dark_widget about-widget pl_20">

          <div class="funding-box">
            <img src="./assets/img/h2020-logo.png" alt="H2020 funding" height="45" />
            <br>
            <br>
            <p>This project has received funding from the European Union’s Horizon 2020 research and innovation
              programme under grant agreement No. 958352
            </p>
          </div>
          <div class="copyright">
            &copy;
            {{test | date: 'yyyy'}}, Developed by
            <a href="https://www.biba.uni-bremen.de/" target="_blank">BIBA</a>.
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<html>

<body>
  <h5 mat-dialog-title> Add new Discount for {{data.service.basic_infos.name}}</h5>
  <div mat-dialog-content>
    <div fxLayout="row"></div>
    <p *ngIf="data.service.operationalData.discount !=null &&
data.service.operationalData.discount.discount != '0'">
      <b>There is currently another discount active.</b>
      <br>
      <b> Please delete the old discount or change its specification</b>
      <br>
      Current discount : {{data.service.operationalData.discount.discount}}%
      <br>
      Running between : {{convertNumberToDate(data.service.operationalData.discount.startDate)}} -
      {{convertNumberToDate(data.service.operationalData.discount.endDate)}}
    </p>
    <p> Enter the discount</p>
    <input class="discountInput" [(ngModel)]="data.discount" required pattern="^\d+(?:[.]\d+)?$">
    <div>
      <mat-form-field fxLayout="row">
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" [(ngModel)]="data.startDate">
          <input matEndDate placeholder="End date" [(ngModel)]="data.endDate">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <br>
    <mat-label>Enter a date range in which the discount is active</mat-label>

    <mat-dialog-actions>
      <button class="dashButton" *ngIf="data.service.operationalData.discount != null &&
data.service.operationalData.discount.discount != '0'" (click)="deleteDiscount()">End active Discount </button>
      <button class="dashButton" (click)="onNoClick()">Cancel</button>
      <button mat-button [mat-dialog-close]="data" class="dashButton" (click)="onOk()">Ok</button>
    </mat-dialog-actions>
  </div>
</body>

</html>
import { LabelSearchInput, ServiceSearchInput } from '../domainModel/ServiceSearchInput';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { BusinessServiceResponse } from '../domainModel/BusinessServiceResponse';
import { HttpClient } from '@angular/common/http';
import { FacetsForPrice, ValuesOfthePriceGoup } from '../domainModel/FacetsForPrice';
import { catchError, map } from 'rxjs/operators';
import { FacetsForStringType, ValuesOftheStringGoup } from '../domainModel/FacetsForStringType';
import { InputForDelete } from '../domainModel/InputForDelete';
import { BusinessService } from '../domainModel/BusinessService';
import { Documentation } from '../domainModel/Documentation';
import { Observable } from 'rxjs';
import { InputDataForRequestReviews } from '../domainModel/InputDataForRequestReviews';
import { FilteredReviews } from '../domainModel/FilteredReviews';

@Injectable({ providedIn: 'root' })
export class ServiceSearchService {

  constructor(private http: HttpClient) {
  }

  getFacetsForPrice() {
    let restAPIURL = environment.searchServiceRootURL + environment.getFacetsForPriceAPI;

    return this.http.get<FacetsForPrice>(restAPIURL)
      .pipe(
        map(responseData => {
          return responseData.valuesOftheGoups;
        },
          catchError(errorRes => {
            // Send to analytics server
            console.error('Fail to getFacetsForPrice')      //This is actually not needed

            const priceGroupArray: ValuesOfthePriceGoup[] = [];
            return priceGroupArray;
          })
        )
      );
  }

  getFacettedForOwner() {
    return this.getFacetsForStringTypes(environment.getFacettedForOwnerAPI);
  }

  getFacettedForCategory() {
    return this.getFacetsForStringTypes(environment.getFacettedForCategoryAPI);
  }

  getFacettedForProducts() {
    return this.getFacetsForStringTypes(environment.getFacettedForPoductsAPI);
  }

  getFacettedForPriceModus() {
    return this.getFacetsForStringTypes(environment.getFacettedForPriceModusAPI);
  }

  getFacettedForTags() {
    return this.getFacetsForStringTypes(environment.getFacettedForTagsAPI);
  }

  private getFacetsForStringTypes(facetAPI: string) {
    let restAPIURL = environment.searchServiceRootURL + facetAPI;

    return this.http.get<FacetsForStringType>(restAPIURL)
      .pipe(
        map(responseData => {
          return responseData.valuesOftheGoups;
        },
          catchError(errorRes => {
            // Send to analytics server
            console.error('Fail to getFacetsForStringTypes')      //This is actually not needed

            const strGroupArray: ValuesOftheStringGoup[] = [];
            return strGroupArray;
          })
        )
      );
  }

  getAllServices() {
    let restAPIURL = environment.searchServiceRootURL + environment.getAllServicesAPI;
    return this.http.post<BusinessServiceResponse>(restAPIURL, {});

  }

  searchForLabels(label: string) {
    let restAPIURL = environment.searchServiceRootURL + environment.searchForLabelsAPI;
    let searchInput: LabelSearchInput = {
      'metasets': [],
      'pageInfo': { 'appliedSkip': 0, 'appliedLimit': 100 },
      'sorting': { 'property': 'lastUpdate', 'direction': 'DESCENDING' },
      'inputString': label
    };

    return this.http.post<BusinessServiceResponse>(restAPIURL, searchInput);
  }

  searchForServicesWithFacettes(searchInput: ServiceSearchInput) {
    let restAPIURL = environment.searchServiceRootURL + environment.searchForServicesWithFacettesAPI;

    return this.http.post<BusinessServiceResponse>(restAPIURL, searchInput);
  }


  saveBusinessService(service: BusinessService) {

    let restAPIURL = environment.searchServiceRootURL + "uploadService";
    return this.http.post<BusinessServiceResponse>(restAPIURL, JSON.parse(JSON.stringify(service)));
  }

  saveDocumentation(docu: Documentation) {

    let restAPIURL = environment.searchServiceRootURL + "uploadDocuments";
    return this.http.post<BusinessServiceResponse>(restAPIURL, JSON.parse(JSON.stringify(docu)));
  }

  checkNameOfService(inputString: string) {
    let restAPIURL = environment.searchServiceRootURL + "checkNameOfService";

    return this.http.post(restAPIURL, { "inputString": inputString });
  }

  updateBusinessService(service: BusinessService) {

    let restAPIURL = environment.searchServiceRootURL + "updateService";
    return this.http.post<String>(restAPIURL, JSON.parse(JSON.stringify(service)));

  }

  updateBusinessServiceDirect(service: BusinessService) {
    let restAPIURL = environment.searchServiceRootURL + "updateServiceDirect";
    return this.http.post<String>(restAPIURL, JSON.parse(JSON.stringify(service)));
  }

  isValidDockerDesc(dockerCom: string) {
    let restAPIURL = environment.deploymentServiceRootURL + "isValidDockerDesc";
    return this.http.post(restAPIURL, dockerCom);
  }

  searchForAllBusinessServicesYouOwn(owner: string) {
    let restAPIURL = environment.searchServiceRootURL + "searchForAllBusinessServicesYouOwn";
    return this.http.post<BusinessServiceResponse>(restAPIURL, { "appliedSkip": 0, "appliedLimit": 100, "sorting": { "property": "lastUpdate", "direction": "DESCENDING" }, "owner": owner });
  }

  searchForBusinessServiceAccordingToID(serviceId:string){
    let  restAPIURL = environment.searchServiceRootURL + "searchForBusinessServiceAccordingToID";
    return this.http.post<BusinessService>(restAPIURL, { "trickServiceID": serviceId});
  }

  searchForAllBusinessServicesYouPurchased(owner: string) {
    let restAPIURL = environment.searchServiceRootURL + "searchForAllBusinessServicesYouPurchased";
    return this.http.post<BusinessServiceResponse>(restAPIURL, { "appliedSkip": 0, "appliedLimit": 100, "sorting": { "property": "lastUpdate", "direction": "DESCENDING" }, "owner": owner });
  }

  deleteBusinessServices(id: string) {

    let searchInput = new InputForDelete();
    searchInput.serviceIDToBeDeleted = id;
    let restAPIURL = environment.searchServiceRootURL + environment.delete;

    return this.http.post<String>(restAPIURL, searchInput);
  }

  requestMyBusinessServices() {
    //searchForAllBusinessServices
    let restAPIURL = environment.searchServiceRootURL + "searchForAllBusinessServices";

    return this.http.post<BusinessServiceResponse>(restAPIURL, {});
  }

  documentByIdService(documentationID: string): Observable<Documentation[]> {
    //get all document based on documentation id
    let restAPIURL = environment.searchServiceRootURL + "searchForAllDocumentsAccordingToID";
    return this.http.post<Documentation[]>(restAPIURL, { documentationID });
  }

  requestStarReviews(serviceId: string, numberOfReviews: number) {
    let input = new InputDataForRequestReviews();
    input.businessServiceID = serviceId;
    input.numberOfReviews = numberOfReviews;

    let restAPIURL = environment.searchServiceRootURL + "requestReviewsForABusinessService"

    return this.http.post<FilteredReviews>(restAPIURL, (input));
  }

  updateServiceDirectForStars(service: BusinessService) {

    let restAPIURL = environment.searchServiceRootURL + "updateServiceDirectForStars"
    return this.http.post<String>(restAPIURL, JSON.parse(JSON.stringify(service)));
  }



}

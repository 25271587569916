import { BusinessServiceStatus } from './BusinessServiceStatus';

export class Deployment {

  status: BusinessServiceStatus = BusinessServiceStatus.COMMITTED;
  urlForDownload: string;
  urlForInvocation: string;
  dockerTag: string;
  dockerDescription: string;
  version: string;
  harbor: boolean;
}

import { Injectable } from '@angular/core';
import { ISession } from "./ISession";
import { BusinessService } from "../domainModel/BusinessService";
import { Subject } from 'rxjs';
import { User } from '../domainModel/User';

@Injectable({ providedIn: 'root', })
export class SessionService implements ISession {
  private _session: BusinessService;
  private loggedIn: boolean;
  private _companyOfUser: string;
  private _emailOfUser: string;
  private _oAuth: string;
  private _curUser: User;

  loginUserChange: Subject<User> = new Subject<User>();

  addBasicDataToStorage(){
    sessionStorage.setItem("user", JSON.stringify(this._curUser));
    sessionStorage.setItem("loggedIn", String(this.loggedIn));
    sessionStorage.setItem("companyOfUser", this._companyOfUser);
    sessionStorage.setItem("emailOfUser", this._emailOfUser);
    sessionStorage.setItem("oAuth", this._oAuth);
  }


  constructor() {
    this.loggedIn = false;
  }

  set session(value) {
    this._session = value;
  }

  get session() {
    return this._session
  }

  get curUser(): User {
    if (this._curUser != null) {
      //console.log ("Deliver local user: " + this._curUser);
      return this._curUser;
    }
    else{
      let user = sessionStorage.getItem("user")
      if (user != null && user.length > 5) {
        this._curUser = JSON.parse(user);
        //console.log ("Deliver stored user: " + this._curUser);
        return this._curUser;
      }
      else{
        return null;
      }
    }
  }

  set curUser(value: User) {
    this._curUser = value;
    sessionStorage.setItem("user", JSON.stringify(value));

    // emit event that the login status has been updated
    this.loginUserChange.next(this.curUser);
  }

  get isLoggedIn() {
    let result = this.loggedIn;
    if (result==false){
      //Second chance
      //console.log("BIN beim isLogged in 0: " + result)
      let rAsString = sessionStorage.getItem("loggedIn");
      //console.log("BIN beim isLogged in 01 " + rAsString)
      if (rAsString != null && rAsString.length > 0) {
        result = rAsString.toLowerCase() == 'true';
        //console.log("BIN beim isLogged in 2: " + result)
        return result;
      }
      else{
        return false;
      }
    }
    return result;
  }

  set isLoggedIn(value) {
    this.loggedIn = value;
    sessionStorage.setItem("loggedIn", String(value));
    //console.log("BIN beim isLogged in  I: " + value)
    //console.log(sessionStorage.getItem("loggedIn"));

    if (!this.loggedIn) {
      this.curUser = null;
      sessionStorage.setItem("user", "");
    }
  }

  get companyOfUser() {
    let value =  this._companyOfUser;
    if (value == null || value.length  < 2){
      value = sessionStorage.getItem("companyOfUser")
      this._companyOfUser = value;
    }

    return value;
  }
  set companyOfUser(value) {
    this._companyOfUser = value;
    sessionStorage.setItem("companyOfUser", value);
  }

  get emailOfUser() {

    let value =  this._emailOfUser;
    if (value == null || value.length  < 2) {
      value = sessionStorage.getItem("emailOfUser");
      this._emailOfUser = value;
    }
    return value;
  }


  set emailOfUser(value) {
    this._emailOfUser = value;
    sessionStorage.setItem("emailOfUser", value);
  }

  get oAuthToken() {
    let value =  this._oAuth;
    if (value == null || value.length  < 2) {
      value = sessionStorage.getItem("oAuth");
      this._oAuth = value;
    }
    return value;
  }
  set oAuthToken(value) {
    this._oAuth = value;
    sessionStorage.setItem("oAuth", value);
  }


}

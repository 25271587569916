import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { BusinessService } from "../domainModel/BusinessService";

@Injectable({ providedIn: 'root' })
export class DataService {
  private curBusinessService: BusinessService;

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  setCurBusinessService(bs: BusinessService): void {
    this.curBusinessService = bs;
  }

  getCurBusinessService(): BusinessService {
    return this.curBusinessService;
  }

}

<style>
  html {
    background-color: white;
    border-radius: 6px !important;
    box-shadow: none;
    margin: 0.375rem;
    padding: 1.84rem 2.14rem;
    font-size: .81rem;
    line-height: 1.35em;
  }

  div {
    align-content: center;
  }
</style>
<html>
<h3 align="center" mat-dialog-title>Do you want to cancel this process?</h3>
<div mat-dialog-content align="center">If you decide to terminate this process, every information
  that you entered will be lost. <br>
  Are you sure to proceed?</div>
<div mat-dialog-actions>
  <button class="dashButton" mat-button mat-dialog-close="">No, I want to continue</button>
  <button class="dashButton" mat-button (click)="onYesClick()">Yes, I want to cancel this process</button>
</div>

</html>
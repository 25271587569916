import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SessionService } from "../../commenServices/SessionService";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PublicApiService } from '../../commenServices/public.api.service';
import { User } from '../../domainModel/User';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  public companyName: string;
  public errorMessage: string;
  public login_status: boolean;
  public curUser: User;

  public isCollapsed = true;
  searchText: string;

  validatingForm: FormGroup;
  @ViewChild('framelogin') public modal: any;

  constructor(location: Location, private element: ElementRef, private router: Router, public sessionService: SessionService, private apiService: PublicApiService) {
    this.location = location;
    this.sidebarVisible = false;

    this.validatingForm = new FormGroup({
      loginFormModalEmail: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      loginFormModalPassword: new FormControl('', Validators.required)

    });

    // subscribe the change of login status
    this.sessionService.loginUserChange.subscribe((loginUser) => {
      this.updateUserDetails();
    })
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.login_status = this.sessionService.isLoggedIn;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
      var $layer: any = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    const html = document.getElementsByTagName('html')[0];
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }

    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');

    this.sidebarVisible = true;
  };
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    this.toggleButton.classList.remove('toggled');
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };
  sidebarToggle() {
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const html = document.getElementsByTagName('html')[0];

    if (this.mobile_menu_visible == 1) {
      html.classList.remove('nav-open');
      if ($layer) {
        $layer.remove();
      }
      setTimeout(function () {
        $toggle.classList.remove('toggled');
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(function () {
        $toggle.classList.add('toggled');
      }, 430);

      var $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');


      if (html.querySelectorAll('.main-panel')) {
        document.getElementsByClassName('main-panel')[0].appendChild($layer);
      } else if (html.classList.contains('off-canvas-sidebar')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }

      setTimeout(function () {
        $layer.classList.add('visible');
      }, 100);

      $layer.onclick = function () { //asign a function
        html.classList.remove('nav-open');
        this.mobile_menu_visible = 0;
        $layer.classList.remove('visible');
        setTimeout(function () {
          $layer.remove();
          $toggle.classList.remove('toggled');
        }, 400);
      }.bind(this);

      html.classList.add('nav-open');
      this.mobile_menu_visible = 1;

    }
  };

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(2);
    }
    titlee = titlee.split('/').pop();

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  search(searchText: string) {
    this.router.navigate(['/search'], { queryParams: { searchText: searchText } });
  }

  login() {
    let status: boolean;
    status = this.sessionService.isLoggedIn
    if (status == false) {
      this.modal.disableClose = true;
      this.modal.show();
    }
  }

  logout() {
    this.sessionService.isLoggedIn = false;
    this.curUser = new User();
    this.sessionService.curUser = this.curUser;
    this.sessionService.emailOfUser = null;

    this.router.navigate(['/dashboard']);
  }

  editProfile() {
    this.router.navigate(['/user-profile']);
  }

  get loginFormModalEmail() {
    return this.validatingForm.get('loginFormModalEmail');
  }

  get loginFormModalPassword() {
    return this.validatingForm.get('loginFormModalPassword');
  }

  success() {
    if (this.loginFormModalEmail.value.length > 0) {
      if ((this.loginFormModalEmail.value.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")) && (this.loginFormModalPassword.value.length > 0)) {

        return true;
      }
      else {
        return false;
      }
    }
    return false;
  }

  checkemail() {
    return this.validatingForm.get('loginFormModalEmail').value.include('@');
  }

  updateUserDetails() {
    this.curUser = this.sessionService.curUser;
  }

  confirmLogin() {
    this.errorMessage = null;
    this.apiService.login(this.validatingForm.get('loginFormModalEmail').value, this.validatingForm.get('loginFormModalPassword').value).subscribe(

      (response) => {
        let token = JSON.stringify(response);
        console.log(response)
        if (token.length > 10) {

          this.sessionService.isLoggedIn = true;
          this.sessionService.oAuthToken = response.token;
          this.sessionService.emailOfUser = this.loginFormModalEmail.value;
          this.sessionService.companyOfUser = this.companyName;

          if (response.email != null){
            this.sessionService.emailOfUser = response.email;
          }
          if (response.firstName != null){

            var currentUser = new User();
            currentUser.email = response.email;
            currentUser.firstName = response.firstName;
            currentUser.lastName = response.lastName;
            currentUser.city = response.city;
            currentUser.address = response.address;
            currentUser.aboutme = response.aboutme;
            currentUser.country = response.country;
            currentUser.organization = response.organization;
            currentUser.postalcode = response.postalcode;
            currentUser.username = response.username;
            this.sessionService.companyOfUser = response.organization;

            this.curUser = currentUser;
            this.sessionService.curUser = this.curUser;
            this.sessionService.emailOfUser = response.email;
          }else {

            console.log("USE the old API and needs to ask for user details")
            this.apiService.getUserDetails(this.sessionService.emailOfUser).subscribe(
              (response) => {
                this.curUser = response;
                this.sessionService.curUser = this.curUser;
                this.sessionService.companyOfUser = response.organization;
              });
          }
          this.modal.hide();
        } else {
          this.errorMessage = "Sorry, your credentials are wrong or the srvice is not reachable";
          console.log("Sorry, your credentials are wrong or the srvice is not reachable");
        }
      }, exception => { alert("Wrong credentials") }
    );

  }

  goToDashBoard() {
    this.modal.hide();
    this.router.navigate(['/dashboard']);
  }

  notifications() {
    this.router.navigate(['/notification-page']);
  }

  accountLogoClick() {
    this.login_status = this.sessionService.isLoggedIn;
  }
}

export class User {

  firstName: string;
  lastName: string;
  email: string;
  password: string;
  username: string;
  organization: string;

  roles: string[];
  address: string;
  city: string;
  country: string;
  postalcode: string;
  aboutme: string;

}

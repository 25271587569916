<html xmlns:itemsPerPage="http://www.w3.org/1999/xhtml" xmlns:currentPage="http://www.w3.org/1999/xhtml">

<body>
  <div mat-dialog-content>
    <h5>Customer details</h5>
  </div>
  <div>
    <table class="table">
      <thead class="text-primary">
        <tr>
          <td>Name</td>
          <td> Purchase Date</td>
          <td>Contact</td>
        </tr>
      </thead>
      <tbody class="table">
        <tr *ngFor="let x of customer | paginate: { itemsPerPage: 5, currentPage: page }">
          <td>
            {{x.customer.firstName}}, {{x.customer.lastName}}
          </td>
          <td>
            {{getDate(x.purchaseDate)}}
          </td>
          <td>
            {{x.customer.email}}
          </td>
          <td>
            <button class="dashButton"> Contact Customer</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="has-text-centered">
      <pagination-controls (pageChange)="page = $event"></pagination-controls>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="dashButton" (click)="onNoClick()">Close</button>
  </div>
</body>

</html>
import { User } from "./User";

export class OperationalData {
  //Added variables for the order management
  //Discount
  discount: Discount;
  //Review for the service detail page
  reviewedText: ReviewText[];
  paymentOnce: boolean;
  paymentRegulary: boolean;
  paymentOnDemand: boolean;
  price: string;
  paymentPeriod: string;
  categories: string[];
  review: Review;
  purchasedBy: customers[];
}

export interface customers {
  customer: User;
  purchaseDate: number;
  accessToken:string;
    isActive:boolean;

}

export interface ReviewText {
  reviewText: string;
  rating: number;
}

export interface Discount {
  startDate: number;
  endDate: number;
  discount: string;
}

export interface Review {
  count5Stars: number;
  count4Stars: number;
  count3Stars: number;
  count2Stars: number;
  count1Stars: number;
  averageStars: number;

}

import { Documentation } from "./Documentation";

export class Version {
  documentationID: string;
  lastUpdate: bigint;
  status: number;
  versionNumber: string;
  supportedLanguages: string[];

  constructor() {
    this.supportedLanguages = [];
  }

}


import { BasicInformation } from "./BasicInformation";
import { Deployment } from "./Deployment";
import { Version } from "./Version";
import { OperationalData } from "./OperationalData";
import { DetailedInformation } from "./DetailedInformation";
import { User } from "./User";
import { Category } from "./Category";


export class BusinessService {

  constructor() {
    this.basic_infos = new BasicInformation();
    this.deployment = new Deployment();
    this.operationalData = new OperationalData();
    this.detailedInformation = new DetailedInformation();
    let version = new Version();
    this.versions = [];
    this.versions.push(version);
    this.selected_categories = [];
    this.owner = new User();



  }

  basic_infos: BasicInformation;
  deployment: Deployment;
  versions = [];
  operationalData: OperationalData;
  detailedInformation: DetailedInformation;
  owner: User;
  id: string;
  selected_categories: Category[];

}

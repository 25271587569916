<section class="breadcrum_area w-100 d-flex justify-content-center align-items-center">
  <div class="panel-header-lg">
    <div class="header text-center headerImg">


      <img src="./assets/img/TRick-logo.png" style="width: 20vw; min-width: 330px;" />
    </div>

  </div>
</section>


<div class="main-content">
  <div class="col-md-12 mainBlock">
    <div class="card">
      <div class="card-body">
        <div class="col-md-12">
          <h4 class="card-title">Order Management </h4>
        </div>

        <mat-tab-group *ngIf="isLoggedIn()">
          <mat-tab tabindex="1">
            <ng-template mat-tab-label>
              <h6>My purchased Business Services</h6>
            </ng-template>
            <!--- Table -->
            <table class="table">
              <thead class="text-primary">
                <tr>
                  <td>Icon</td>

                  <td>Service Name</td>
                  <td>Status</td>
                  <td>Purchase Date</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody class="table">
                <tr *ngFor="let x of boughtServices;">
                  <td>
                    <img [src]='mapToByteArray(x.basic_infos.icon)' />
                  </td>
                  <td>
                    {{x.basic_infos.name}}
                  </td>
                  <td>
                    {{findRightDataStatus(x)}}
                  </td>
                  <td>
                    {{findRightData(x)|date:'mediumDate'}} {{findRightData(x)|date:'shortTime'}}
                  </td>
                  <td>
                    <button class="dashButton" (click)="startService(x)"> Get Started</button>
                    <button class="dashButton" (click)="openReviewDialog(x)"> Write Review</button>
                  </td>
                </tr>
              </tbody>
            </table>


          </mat-tab>
          <mat-tab tabindex="2">
            <ng-template mat-tab-label>
              <h6>My Business Service Stats</h6>
            </ng-template>

            <div class="mat-elevation-z8">
              <table class="table" id="secondTable">
                <thead class="text-primary">
                  <tr>
                    <td>Icon</td>
                    <td>Service Name</td>

                    <td class="date"> Release Date</td>
                    <td> Total Amount of Customer</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody class="table">
                  <tr *ngFor="let x of ownServices;">
                    <td>
                      <img [src]='mapToByteArray(x.basic_infos.icon)' />

                    </td>
                    <td>
                      {{x.basic_infos.name}}
                    </td>
                    <td>
                      {{x.basic_infos.creationDate|date:'mediumDate'}} {{x.basic_infos.creationDate|date:'shortTime'}}
                    </td>
                    <td>
                      {{x.operationalData.purchasedBy.length}}
                      <button class="dashButton" (click)="openCustomerDialog(x)"> Details</button>
                    </td>
                    <td>
                      <button *ngIf="!(x.operationalData.paymentOnDemand)" class="dashButton"
                        (click)="openPriceDialog(x)"> Change Price</button>


                      <button *ngIf="!(x.operationalData.paymentOnDemand)" class="dashButton"
                        (click)="openDiscountDialog(x)"> Add Discount</button>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>


          </mat-tab>

        </mat-tab-group>


      </div>
    </div>


    <div mdbModal #frameMyServices="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
      aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h4 class="modal-title w-100 font-weight-bold">Sign in</h4>

          </div>
          <div class="modal-body mx-3">
            <div class="md-form mb-5">
              <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
              <input type="email" id="defaultForm-emailMyServices" [formControl]="loginFormModalEmail"
                class="form-control" mdbInput mdbValidate>
              <label for="defaultForm-emailMyServices">Your e-mail address</label>
              <mdb-error
                *ngIf="loginFormModalEmail.invalid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
                Input invalid
              </mdb-error>
              <mdb-success
                *ngIf="loginFormModalEmail.valid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
                Input
                valid
              </mdb-success>
            </div>
            <div class="md-form mb-4">
              <mdb-icon fas icon="lock" class="prefix grey-text"></mdb-icon>
              <input type="password" id="defaultForm-pass" [formControl]="loginFormModalPassword" class="form-control"
                mdbInput mdbValidate>
              <label for="defaultForm-pass">Your password</label>
              <mdb-error
                *ngIf="loginFormModalPassword.invalid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
                Input invalid
              </mdb-error>
              <mdb-success
                *ngIf="loginFormModalPassword.valid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
                Input valid
              </mdb-success>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button mdbBtn color="default" class="waves-light" *ngIf="success()" (click)="confirmLogin()"
              mdbWavesEffect>Login</button>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button mdbBtn color="default" class="waves-light" (click)="goToDashBoard()" mdbWavesEffect>Cancel</button>
          </div>
        </div>
      </div>
    </div>


  </div>

</div>

<nav class="navbar fixed-top navbar-expand-lg navbar-dark navbar-absolute p-md-3">
  <div class="navbarmain">
    <ul>
      <li>
      </li>
      <li>
        <input type="text" [(ngModel)]="searchText" (keyup.enter)="search(searchText)" class="search-bar"
          placeholder="Trick services">
      </li>
      <li>
        <button class="search-button" type="button" (click)="search(searchText)">Search</button>
      </li>



      <li *ngIf="sessionService.curUser" class="user-account">
        Hello, {{this.sessionService.curUser.firstName}} {{this.sessionService.curUser.lastName}}
      </li>

      <li>
        <i class="now-ui-icons users_single-02 user-account" (click)="accountLogoClick()"
          [matMenuTriggerFor]="menu"></i>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="login()" [disabled]=this.login_status>Login</button>
          <button mat-menu-item (click)="logout()" [disabled]=!this.login_status>Logout</button>
          <button mat-menu-item (click)="editProfile()">Edit profile </button>
          <button mat-menu-item (click)="notifications()">Notifications</button>
        </mat-menu>

      </li>
    </ul>
  </div>
</nav>

<div mdbModal #framelogin="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog" role="document" back>
    <div class="modal-content">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Sign in with your e-mail</h4>

      </div>
      <div class="modal-body mx-3">

        <div class="md-form mb-5">


          <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
          <input type="email" id="defaultForm-emaillogin" [formControl]="loginFormModalEmail" class="form-control"
            mdbInput mdbValidate>
          <label for="defaultForm-emaillogin">Your e-mail adress</label>
          <mdb-error *ngIf="loginFormModalEmail.invalid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
            Input invalid
          </mdb-error>
          <mdb-success *ngIf="loginFormModalEmail.valid && (loginFormModalEmail.dirty || loginFormModalEmail.touched )">
            Input
            valid
          </mdb-success>
        </div>
        <div class="md-form mb-4">
          <mdb-icon fas icon="lock" class="prefix grey-text"></mdb-icon>
          <input type="password" id="defaultForm-pass" [formControl]="loginFormModalPassword" class="form-control"
            mdbInput mdbValidate>
          <label for="defaultForm-pass">Your password</label>
          <mdb-error
            *ngIf="loginFormModalPassword.invalid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
            Input invalid
          </mdb-error>
          <mdb-success
            *ngIf="loginFormModalPassword.valid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
            Input valid
          </mdb-success>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="default" class="waves-light" *ngIf="success()" (click)="confirmLogin()"
          mdbWavesEffect>Login</button>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn color="default" class="waves-light" (click)="goToDashBoard()" mdbWavesEffect>Cancel</button>
      </div>
      <div class="alert alert-danger" *ngIf="errorMessage">Your credentials are wrong</div>
    </div>
  </div>
</div>

<div class="logo">
    <a href="https://www.trick-project.eu/" class="simple-text logo-mini">
        <div class="logo-img">
            <img src="./assets/img/TRick-logo.png" />
        </div>
    </a>
</div>
<div class="sidebar-wrapper">
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a [routerLink]="[menuItem.path]">
                <i class="now-ui-icons {{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
</div>
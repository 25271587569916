<div class="main-content">
  <div class="col-md-12 mainBlock">
    <div class="card">
      <div class="card-body">

        <div class="col-md-12">
          <h5 class="card-title">Deployment Information </h5>
          <div class="Container">

            <!-- Version-->
            <div class="form-check">
              <label> Version*</label>
              <input type="text" class="inputForm versionInput" [(ngModel)]="version" required />
            </div>
            <div class="form-check">
            <mat-divider class="divider-class"></mat-divider>

            <label id="example-radio-group-label">Service deployment*</label>
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group">
              <mat-radio-button (click)= "clickDeploymentType('url')" class="example-radio-button" value="url">
                URL
              </mat-radio-button>
              <mat-radio-button  (click)= "clickDeploymentType('docker')" class="example-radio-button" value="docker">
                Docker
              </mat-radio-button>
            </mat-radio-group>

            <div *ngIf="urlDeployment">
              <mat-divider class="divider-class"></mat-divider>
              <label style="vertical-align: top;" matTooltip="Please enter deployment URL."
              matTooltipClass="example-tooltip-red">Deplyment URL*</label>
                  <input class="inputForm url_box" [(ngModel)]="serviceUrl" required />
            </div>
          </div>
            <div *ngIf="dockerDeployment">
            <mat-divider class="divider-class"></mat-divider>
            <!--URL -->

            <div class="form-check">
              <label style="vertical-align: top;" matTooltip="Please enter the content of your Docker-Tag-File."
                matTooltipClass="example-tooltip-red">Define the Docker Tag*</label>
              <textarea style="margin-left: 8.5%;" class="inputForm" id="dockerTag" type="text" cols="30" rows="5"
                [(ngModel)]="dockerTag" (blur)="checkValidityOfDockerTag(dockerTag)"
                pattern="^[a-zA-Z0-9]+([_\-\.]*[a-zA-Z0-9]+)*(:[a-zA-Z0-9]+([_\-\.]*[a-zA-Z0-9]+)*)?$"
                [ngModelOptions]="{updateOn: 'blur'}"> </textarea>
              <br>

              <mat-divider class="divider-class"></mat-divider>
              <label style="vertical-align: top;" matTooltip="Please enter the content of your Docker-Composer-Filer"
                matTooltipClass="example-tooltip-red">Define the Docker Compose File*</label>
              <textarea style="margin-left: 2.1%;" class="inputForm" id="dockerCompose" cols="30" rows="5"
                #dockerComposeInputField="ngModel" [(ngModel)]="dockerComposeFile" required
                (blur)="checkValidityOfDocker(dockerComposeFile)" dockerComposeVadidator
                [ngModelOptions]="{updateOn: 'blur'}"></textarea>


              <br>
            </div>
            </div>
            <div class="form-check">
              <mat-divider class="divider-class"></mat-divider>

              <p>See if your service is already available on Harbor</p>
              <button
                onclick="window.open('https://reg.biba.uni-bremen.de/account/sign-in?redirect_url=%2Fharbor%2Fprojects', '_blank')"
                class="dashButton">Go to Harbor</button>
              <br>
              <label class="form-check-label">
                <input [(ngModel)]="checkUpload" class="inputForm form-check-input" type="checkbox" value="" required>
                The Upload was successful
                <span class="form-check-sign"></span>
              </label>

              <mat-divider class="divider-class"></mat-divider>
              <div class="Container">
                <p>* Please fill out the required fields.</p>
              </div>


            </div>
          </div>
          <!ButtonContainer>
            <div class="form-check" fxLayout="row">
              <div class="buttonGroup">
                <button mat-button matStepperPrevious class="dashButton" (click)="gotoPrev()">Prev
                  <!--Meike: I removed the btn-block, so that the buttons are displayed in a row-->
                </button>
                <button [disabled]="!unableButton" mat-button matStepperNext class="dashButton"
                  (click)="gotoNext()">Next </button>


              </div>
            </div>

        </div>
      </div>
    </div>

  </div>
</div>
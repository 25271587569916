<html>

<body>
  <div mat-dialog-content>
    <h5>Write a review</h5>

    <div class="rate">
      <input type="radio" id="star5" name="rate" value="5" [(ngModel)]="data.rating" />
      <label for="star5" title="text">5 stars</label>
      <input type="radio" id="star4" name="rate" value="4" [(ngModel)]="data.rating" />
      <label for="star4" title="text">4 stars</label>
      <input type="radio" id="star3" name="rate" value="3" [(ngModel)]="data.rating" />
      <label for="star3" title="text">3 stars</label>
      <input type="radio" id="star2" name="rate" value="2" [(ngModel)]="data.rating" />
      <label for="star2" title="text">2 stars</label>
      <input type="radio" id="star1" name="rate" value="1" [(ngModel)]="data.rating" />
      <label for="star1" title="text">1 star</label>
    </div>
    <div>
      <textarea class="reviewText" cols="30" rows="10" [(ngModel)]="data.reviewText"></textarea>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="dashButton" (click)="onNoClick()">Cancel</button>
    <button id="btn" class="dashButton" (click)="onOk()">Ok</button>
  </div>
</body>

</html>
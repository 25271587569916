<html>

<body>
  <div mat-dialog-content>
    <p>Enter the new price.</p>
    <p> The current price is: {{data.currentPrice}}</p>
    <input class="priceInput" [(ngModel)]="data.newPrice" required pattern="^\d+(?:[.]\d+)?$">
    <label class="priceLabel"> €</label>
  </div>
  <div mat-dialog-actions>
    <button class="dashButton" (click)="onNoClick()">Cancel</button>
    <button class="dashButton" [mat-dialog-close]="data.newPrice" (click)="onOk()">Ok</button>
  </div>
</body>

</html>
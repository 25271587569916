import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { ServiceSearchService } from '../commenServices/search.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Directive({
  selector: '[dockerComposeVadidator]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: DockerComposeFileValidatorDirective, multi: true }]
})


export class DockerComposeFileValidatorDirective implements AsyncValidator {
  constructor(private serviceSearch: ServiceSearchService) { }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const obs = this.serviceSearch.isValidDockerDesc(control.value)
      .pipe(
        map(
          (response) => {
            let resp = JSON.stringify(response);
            if (resp == 'true') {
              console.log('valid response from DummyValidatorDirective');
              return null;
            } else {
              console.log('invalid response from DummyValidatorDirective');

              return { 'status': 'invalid' };
            }
          },
          (error) => {
            console.error("Request denied");
            console.log(error)
            console.log('error response from DummyValidatorDirective');
            return { 'status': 'invalid' };
          }
        )
      );
    return obs;
  }

}

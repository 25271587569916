import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import {SessionService} from "./commenServices/SessionService";
import {MatListModule} from '@angular/material/list';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import {MatTooltipModule} from "@angular/material/tooltip";
import {DialogAbort} from "./publish-page4/publish.component";
import {MatDialogModule} from "@angular/material/dialog";
import{DialogPrice, DialogDiscount, CustomerDialog, ReviewDialog} from "./order-management/icons.component";
import {MatTabsModule} from "@angular/material/tabs";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatNativeDateModule} from "@angular/material/core";
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from "@angular/material/input";
import {NgxPaginationModule} from "ngx-pagination";
import {ComposerDialog} from "./publish-page3/publish.component";

@NgModule({
  imports: [
    MatListModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgbModule,
    MDBBootstrapModule.forRoot(),
    ToastrModule.forRoot(),
    MatDialogModule,
    MatTabsModule,
    MatDatepickerModule,
    MatStepperModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    NgxPaginationModule,

  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    DialogAbort,
    DialogPrice,
    DialogDiscount,
    CustomerDialog,
    ReviewDialog,
    ComposerDialog
  ],
  providers: [SessionService],
  bootstrap: [AppComponent]
})
export class AppModule { }

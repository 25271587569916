<div class="main-content">
  <div class="col-md-12 mainBlock">
    <div class="card">
      <div class="card-body">

        <div class="col-md-12">
          <h5 class="card-title">Service Compliance</h5>

          <!Guidlines>
            <div class="form-check" fxLayout="row">
              <label class="form-check-label">
                <label>You followed the design guidelines?</label>
                <input [(ngModel)]="hasBeenDesignGuidelinesConsidered" class="form-check-input" type="checkbox"
                  value="">
                <span class="form-check-sign"></span>
              </label>
            </div>

            <!GDPR>
              <div class="form-check" fxLayout="row">
                <label class="form-check-label">
                  <label>Is it GDPR compliant?</label>
                  <input [(ngModel)]="isGDPRComplianceConsidered" class="form-check-input" type="checkbox" value="">
                  <span class="form-check-sign"></span>
                </label>
              </div>

              <!standards>
                <div class="form-check" fxLayout="row">
                  <label class="form-check-label">
                    <label> Does it cover relevant standards?</label>
                    <input [(ngModel)]="isLegalIssuesConsidered" class="form-check-input" type="checkbox" value="">
                    <span class="form-check-sign"></span>
                  </label>
                </div>

                <!aspects>
                  <div class="form-check" fxLayout="row">
                    <label class="form-check-label">
                      <label>Does it cover relevant aspects?</label>
                      <input [(ngModel)]="isRelevantStandardsConsidered" class="form-check-input" type="checkbox"
                        value="">
                      <span class="form-check-sign"></span>
                    </label>
                  </div>

                  <mat-divider class="divider-class"></mat-divider>


                  <!Buttons>
                    <div class="form-check" fxLayout="column">
                      <div class="buttonGroup">
                        <button mat-button matStepperPrevious class="dashButton" (click)="gotoPrev()">Prev
                        </button>
                        <button mat-button matStepperNext class="dashButton" (click)="gotoOptional()">Add Optional
                          Information
                        </button>
                        <button class="dashButton" *ngIf="!(currentlyUpdating)" (click)="gotoNext()">Finish
                        </button>
                        <button class="dashButton" *ngIf="currentlyUpdating" (click)="saveUpdate()"> Save
                          Changes</button>
                        <button class="dashButton" (click)="openAbortDialog()" *ngIf="currentlyUpdating">Abort</button>

                      </div>

                    </div>
        </div>
      </div>
    </div>
  </div>


</div>
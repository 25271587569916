import { Injectable } from '@angular/core';
import { SessionService } from './SessionService';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { InputDataForPurchaseApproval } from '../domainModel/InputDataForPurchaseApproval';
import { BusinessService } from '../domainModel/BusinessService';
import {InputDataFurAnnouncePurchase} from '../domainModel/InputDataFurAnnouncePurchase';
import {InputDataForAnnounceStart} from '../domainModel/InputDataForAnnounceStart';

@Injectable({
  providedIn: 'root'
})


export class DummyBizServiceService {
  constructor(private sessionService: SessionService, private http: HttpClient) {
  }

  rootPath = environment.dummyBizServiceRootURL;

  purchaseService(bizService: BusinessService) {
    let restAPI = this.rootPath + 'purchase';

    let input = new InputDataForPurchaseApproval();

    input.businessServiceID = bizService.id;
    input.price = bizService.operationalData.price;
    // TODO: it is dummy value, it should be set to the real value in the real business service
    input.discount = "";
    input.accessToken = this.sessionService.oAuthToken;
    input.userID = this.sessionService.curUser.email;

    return this.http.post(restAPI, JSON.parse(JSON.stringify(input)))
  }

  announcePurchaseService(bizService: BusinessService) {
   // let restAPI = bizService.deployment.urlForInvocation + 'announcePurchase';
   // let restAPI = 'http://localhost:8088' + 'announcePurchase';
 let newURL = environment.searchServiceRootURL + 'announcePurchase'
 //   let newURL = 'http://localhost:8088/'  + 'announcePurchase'
    let input = new InputDataForPurchaseApproval();

   // input.businessServiceID = bizService.id;
   // input.price = bizService.operationalData.price;
    // TODO: it is dummy value, it should be set to the real value in the real business service
    //input.discount = "";
    //input.accessToken = this.sessionService.oAuthToken;
    input.userID = this.sessionService.curUser.email;

    let input2 = new InputDataFurAnnouncePurchase();
    input2.userId = this.sessionService.curUser.email;
    input2.url = bizService.deployment.urlForInvocation;
    console.log(input2);

    return this.http.post(newURL, JSON.parse(JSON.stringify(input2)))
  }



  announceStartService(bizService: BusinessService) {

    let newURL = environment.searchServiceRootURL + 'announceLogin'
    let input = new InputDataForAnnounceStart()

    input.url = bizService.deployment.urlForInvocation;
    input.securityBrokerToken = this.sessionService.oAuthToken;
    let id = this.sessionService.curUser.email;
    for (var user of bizService.operationalData.purchasedBy){
      if (user.customer.email === id){
          input.accessToken = user.accessToken;
      }
    }


    console.log(input);

    return this.http.post(newURL, JSON.parse(JSON.stringify(input)))
  }




  callService(user:string, baseURL:string){

    let url = baseURL + 'purchase/' + btoa(user);
    return this.http.get(url);
  }

}

import { Component, OnInit } from '@angular/core';
import { BusinessService } from '../domainModel/BusinessService'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SessionService } from "../commenServices/SessionService";
import { Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceSearchService } from "../commenServices/search.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-publish-3',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.css']
})
export class PublishComponentPage3 implements OnInit {

  currentlyUpdating: boolean;
  validDocker: boolean = false;
  optionURL: boolean;
  urlForRemote: string;
  dockerComposeFile: string;
  dockerTag: string;
  version: string;
  checkUpload: boolean;

  unableButton: boolean = false;
  subscription: Subscription;
  deploymentType:string;
  urlDeployment:boolean=false;
  dockerDeployment:boolean = false;
  serviceUrl: string;

  clickDeploymentType(deployment:string){
    // if(this.deploymentType === 'url') {
    if(deployment === 'url') {
      this.urlDeployment = true;
      this.dockerDeployment = false;
    // }if(this.deploymentType === 'docker') {
    }if(deployment === 'docker') {
      this.urlDeployment = false;
      this.dockerDeployment = true;
    }

  }

  gotoPrev() {
    this.dockerTag != undefined ? localStorage.setItem("dockerTag", this.dockerTag) : localStorage.setItem("dockerTag", "");
    this.dockerComposeFile != undefined ? localStorage.setItem("dockerComposer", this.dockerComposeFile) : localStorage.setItem("dockerComposer", "");
    this.serviceUrl != undefined ? localStorage.setItem("serviceUrl", this.serviceUrl) : localStorage.setItem("serviceUrl", "");
  }

  gotoNext() {
    let toBeSaved = new BusinessService();
    toBeSaved = this.sessionService.session;
    toBeSaved.versions[0].versionNumber = this.version;


    toBeSaved.deployment.dockerDescription = this.dockerComposeFile;
    toBeSaved.deployment.dockerTag = this.dockerTag;
    toBeSaved.deployment.version = this.version;
    toBeSaved.deployment.harbor = this.checkUpload;
    toBeSaved.deployment.urlForInvocation = this.serviceUrl;
    if(!this.urlDeployment && !this.dockerDeployment){
      this._snackBar.open("Please select the service deployment type", 'close', { duration: 3000, horizontalPosition: 'center', verticalPosition: 'top' });
      return;
    }
    if(this.dockerDeployment){
    if (this.dockerTag != undefined) {
      localStorage.setItem("dockerTag", this.dockerTag);
    } else {
      this._snackBar.open("Please enter the content of your docker tag file", 'close', { duration: 3000, horizontalPosition: 'center', verticalPosition: 'top' });
      return;
    }
    if (this.dockerComposeFile != undefined) {
      localStorage.setItem("dockerComposer", this.dockerComposeFile);
    } else {
      this._snackBar.open("Please enter the content of your docker compose file", 'close', { duration: 3000, horizontalPosition: 'center', verticalPosition: 'top' });
      return;
    }
  }
  if(this.urlDeployment){
    if (this.serviceUrl != undefined) {
      localStorage.setItem("serviceUrl", this.serviceUrl);
    } else {
      this._snackBar.open("Please enter the content of your service url", 'close', { duration: 3000, horizontalPosition: 'center', verticalPosition: 'top' });
      return;
    }

  }
    if (this.version != undefined) {
      localStorage.setItem("version", this.version);
    } else {
      this._snackBar.open("Please define the version of your docker", 'close', { duration: 3000, horizontalPosition: 'center', verticalPosition: 'top' });
      return;
    }
    this.sessionService.session = toBeSaved;
  }

  dockerTagValidResponse() {
    if (!this.validDocker) {
      this.openComposerDialog();
    } else {
      return;
    }
  }

  openComposerDialog() {
    const dialogRef = this.dialog.open(ComposerDialog);
    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed");
    })
  }

  checkValidityOfDockerTag(dockerTag: string) {
    if (!dockerTag) {
      console.log("given docker tag is empty!");
      this._snackBar.open("docker tag is empty!", 'close', { duration: 3000, horizontalPosition: 'center', verticalPosition: 'top' });
      return false;
    }
    // docker tag should be in the format: TARGET_IMAGE[:TAG]. according to https://docs.docker.com/engine/reference/commandline/tag/
    // it may contain lowercase letters, digits and separators. A separator is defined as a period, one or two underscores, or one or more hyphens. A name component may not start or end with a separator
    const regex = /^[a-zA-Z0-9]+([_\-\.]*[a-zA-Z0-9]+)*(:[a-zA-Z0-9]+([_\-\.]*[a-zA-Z0-9]+)*)?$/;

    if (regex.test(dockerTag)) {
      return true;
    } else {
      this._snackBar.open("The docker tag " + dockerTag + "is invalid. It should be in the format:  TARGET_IMAGE[:TAG], following the  https://docs.docker.com/engine/reference/commandline/tag/. A valid tag is, for example, httpd:version1.0.test ", 'close', { duration: 3000, horizontalPosition: 'center', verticalPosition: 'top' });
      return false;
    }
  }



  checkValidityOfDocker(image: string) {
    this.serviceSearch.isValidDockerDesc(image).subscribe(
      (response) => {
        let resp = JSON.stringify(response);
        if (resp == 'true') {
          this.validDocker = true;
        } else {
          this.validDocker = false;
        }
        this.dockerTagValidResponse();
      },
      (error) => {
        console.error("Request denied");
        console.log(error)
      }
    )

  }

  resetInformation() {
    this.dockerTag = localStorage.getItem("dockerTag");
    this.dockerComposeFile = localStorage.getItem("dockerComposer");
    this.version = localStorage.getItem("version");
  }





  constructor(public dialog: MatDialog, private http: HttpClient, private router: Router, private sessionService: SessionService, private serviceSearch: ServiceSearchService, private _snackBar: MatSnackBar) {
  }

  setUpdateService() {
    let _version = localStorage.getItem("_dockerVersion");
    let tag = localStorage.getItem("_dockerTag");
    let composer = localStorage.getItem("_dockerComposer");
    let _serviceUrl = localStorage.getItem("_serviceUrl");
    if(tag === 'null'){
      this.dockerTag = "";
    } else{
    this.dockerTag = tag;
    }
    this.version = _version;
    if(composer === 'null'){
    this.dockerComposeFile = "";
    } else {
      this.dockerComposeFile = composer;
    }
    if (_serviceUrl === 'null') {
    this.serviceUrl = "";
    } else {
      this.serviceUrl = _serviceUrl;    
    }
  }

  ngOnInit() {

    this.subscription = timer(0, 500).pipe(
      map(() => {
        this.unableDisableButton(); // load data contains the http request
      })
    ).subscribe();

    if (localStorage.getItem("_dockerTag") != undefined) {
      this.currentlyUpdating = true;
      localStorage.setItem("page", "3");

      this.setUpdateService();
    }
    if (localStorage.getItem("dockerTag") != undefined || localStorage.getItem("dockerTag") != ""
      || localStorage.getItem("page") === "2" || localStorage.getItem("page") === "4") {
      if (localStorage.getItem("update") == undefined) {
        this.resetInformation();
      }
    }
    localStorage.setItem("page", "3");


  }
  unableDisableButton() {
    if(this.dockerDeployment){
    if (this.dockerTag != undefined && this.dockerTag != '') {
      if (this.dockerComposeFile != undefined && this.dockerComposeFile != '') {
        if (this.version != undefined && this.version != '') {
          if (this.checkUpload != undefined && this.checkUpload != false) {
            this.unableButton = true;
          } else {
            this.unableButton = false;
          }
        } else {
          this.unableButton = false;
        }


      } else {
        this.unableButton = false;
      }

    } else {
      this.unableButton = false;
    }
  }
    if(this.urlDeployment){
        if (this.serviceUrl != undefined && this.serviceUrl != '') {
          if (this.version != undefined && this.version != '') {
            // if (this.checkUpload != undefined && this.checkUpload != false) {
              this.unableButton = true;
            // } else {
              // this.unableButton = false;
            //}
          } else {
            this.unableButton = false;
          }
  
  
        } else {
          this.unableButton = false;
        }

    }

  }

  saveIcon() {
    let toBeSaved = new BusinessService();
    toBeSaved = this.sessionService.session;
  }


}

@Component({
  selector: 'composer-information',
  templateUrl: 'ComposerInformation.html',
  styleUrls: ['./dialog.css']
})

export class ComposerDialog {
  constructor(public dialogRef: MatDialogRef<ComposerDialog>) {
  }

  onOk(): void {
    this.dialogRef.close();
  }
}

import { Component, OnInit } from '@angular/core';
import {BusinessService} from '../domainModel/BusinessService'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {SessionService} from "../commenServices/SessionService";
import { ServiceSearchService } from '../commenServices/search.service';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-publish-4',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.css']
})
export class PublishComponentPage4 implements OnInit {
  currentlyUpdating:boolean;
  isGDPRComplianceConsidered:boolean;
  isLegalIssuesConsidered:boolean;
  isRelevantStandardsConsidered:boolean;
  hasBeenDesignGuidelinesConsidered:boolean
  gotoPrev(){
    this.saveStandards();
  }

  saveStandards(){
    localStorage.setItem("GDPR", String(this.isGDPRComplianceConsidered));
    localStorage.setItem("DesignGuide", String(this.hasBeenDesignGuidelinesConsidered));
    localStorage.setItem("LegalIssues", String(this.isLegalIssuesConsidered));
    localStorage.setItem("RelevantStandards", String(this.isRelevantStandardsConsidered));

    let toBeSaved = new BusinessService();
    toBeSaved = this.sessionService.session;
    toBeSaved.detailedInformation.isGDPRComplianceConsidered = this.isGDPRComplianceConsidered;
    toBeSaved.detailedInformation.isLegalIssuesConsidered = this.isLegalIssuesConsidered;
    toBeSaved.detailedInformation.isRelevantStandardsConsidered = this.isRelevantStandardsConsidered;
    toBeSaved.detailedInformation.isIncludeDesignGuidelines=this.hasBeenDesignGuidelinesConsidered;
    this.sessionService.session = toBeSaved;


  }

  resetStandards(){
    (localStorage.getItem("GDPR") === "true") ? this.isGDPRComplianceConsidered =true : this.isGDPRComplianceConsidered=false;
    (localStorage.getItem("DesignGuide") === "true") ? this.hasBeenDesignGuidelinesConsidered =true : this.hasBeenDesignGuidelinesConsidered=false;
    (localStorage.getItem("LegalIssues") === "true") ? this.isLegalIssuesConsidered =true : this.isLegalIssuesConsidered=false;
    (localStorage.getItem("RelevantStandards") === "true") ? this.isRelevantStandardsConsidered =true : this.isRelevantStandardsConsidered=false;
  }

  saveUpdate(){

    let toBeSaved = new BusinessService();
    toBeSaved = this.sessionService.session;
    toBeSaved.detailedInformation.isGDPRComplianceConsidered = this.isGDPRComplianceConsidered;
    toBeSaved.detailedInformation.isLegalIssuesConsidered = this.isLegalIssuesConsidered;
    toBeSaved.detailedInformation.isRelevantStandardsConsidered = this.isRelevantStandardsConsidered;
    toBeSaved.detailedInformation.isIncludeDesignGuidelines=this.hasBeenDesignGuidelinesConsidered;
    this.searchservice.updateBusinessService(toBeSaved).subscribe(
      (response) => {                           //next() callback
        this.router.navigate(['/publish']);
      },
      (error) => {//error() callback
        console.log(error);
        console.error('Request failed with error')

      },
      () => {                                   //complete() callback
        console.error('Request completed')      //This is actually not needed

      })

    //after the update the local storage needs to be cleaned
    localStorage.clear();
  }
  gotoNext() {
    if(this.isGDPRComplianceConsidered !=undefined ||
    this.hasBeenDesignGuidelinesConsidered != undefined ||
    this.isLegalIssuesConsidered != undefined ||
    this.isRelevantStandardsConsidered != undefined){
      localStorage.clear();
    }else{
      // alert("Please re-consider the Standards, Design Guides and other Issues");
      this._snackBar.open("Please re-consider the Standards, Design Guides and other Issues", 'close',{duration:3000, horizontalPosition: 'center', verticalPosition: 'top'});
      return;
    }
    let toBeSaved = new BusinessService();
    toBeSaved = this.sessionService.session;
    toBeSaved.detailedInformation.isGDPRComplianceConsidered = this.isGDPRComplianceConsidered;
    toBeSaved.detailedInformation.isLegalIssuesConsidered = this.isLegalIssuesConsidered;
    toBeSaved.detailedInformation.isRelevantStandardsConsidered = this.isRelevantStandardsConsidered;
    toBeSaved.detailedInformation.isIncludeDesignGuidelines=this.hasBeenDesignGuidelinesConsidered;

    
    this.searchservice.saveBusinessService(toBeSaved).subscribe(
      (response) => {                           //next() callback
        this.router.navigate(['/publish']);
      },
      (error) => {//error() callback
        console.log(error);
        console.error('Request failed with error')

      },
      () => {                                   //complete() callback
        console.error('Request completed')      //This is actually not needed

      })

  }






  constructor(private searchservice: ServiceSearchService, private http: HttpClient, private router: Router, private sessionService: SessionService,
              private dialog:MatDialog, private _snackBar: MatSnackBar) {
  }

  setUpdateService(){
    localStorage.getItem("_GDPR") === "true" ? this.isGDPRComplianceConsidered = true : this.isGDPRComplianceConsidered = false;
    localStorage.getItem("_Legal") === "true" ? this.isLegalIssuesConsidered = true : this.isLegalIssuesConsidered = false;
    localStorage.getItem("_Design") === "true" ? this.hasBeenDesignGuidelinesConsidered = true : this.hasBeenDesignGuidelinesConsidered = false;
    localStorage.getItem("_Standard") === "true" ? this.isRelevantStandardsConsidered = true : this.isRelevantStandardsConsidered = false;
  }


  ngOnInit() {
    if(localStorage.getItem("addedInfo") === "true"){
      this.resetStandards();
    }
    if(localStorage.getItem("_GDPR") != undefined){
      localStorage.setItem("page", "4");
      this.currentlyUpdating = true;
      this.setUpdateService();
    }
    if(localStorage.getItem("GDPR") != undefined || localStorage.getItem("GDPR") != "" ||
    localStorage.getItem("page") === "3" || localStorage.getItem("page") === "5"){
      if(localStorage.getItem("update") == undefined) {
        this.resetStandards();
      }
    }
    localStorage.setItem("page", "4");


  }

  openAbortDialog(){
    const dialogRef = this.dialog.open(DialogAbort, {
      width: 'max-content'
    })
    dialogRef.afterClosed().subscribe(result =>{

    })

  }

  saveIcon(){
    let toBeSaved = new BusinessService();
    toBeSaved = this.sessionService.session;
  }

  gotoOptional() {
    this.saveStandards();
  }
}

@Component({
    selector: 'abortDialog',
    templateUrl: 'abortDialog.html',
  }
)
export class DialogAbort{
  constructor(public dialogRef:MatDialogRef<DialogAbort>, private router: Router, private sessionService:SessionService) {
  }

  onYesClick():void{
    this.dialogRef.close();
    this.router.navigate(['/publish'])

  }

}

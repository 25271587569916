<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">

</head>

<body>
  <h6>Your Composer file was not valid.</h6>
  <h6>Please re-enter a valid Composer File</h6>
  <p>
    For a valid Composer File you will need the following parts in the YAML-File:

  </p>
  <ol>
    <li> The name of your service.</li>
    <li> An image to be pulled</li>
  </ol>
  <p>
    The following two code snippets would be a valid Composer file with these two parts:
  </p>
  <pre class="code">
  <code>trick-frontend:</code>
  <code>    image: reg.biba.uni-bremen.de/trick/front-end:latest</code>
  <code>    ports:</code>
  <code>      - "4200:80"</code>
  <code>    networks:</code>
  <code>      - infra</code>
</pre>
  <pre class="code">
  <code>fuseki_level_up:</code>
  <code>    image: stain/jena-fuseki </code>

</pre>
  <mat-dialog-actions>
    <button mat-button class="dashButton" (click)="onOk()">Ok</button>
  </mat-dialog-actions>

</body>

</html>
import {Component, OnInit, ViewChild, Inject, HostListener} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from "@angular/material/table";
import { BusinessService } from "../domainModel/BusinessService";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceSearchService } from "../commenServices/search.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { SessionService } from "../commenServices/SessionService";
import { DataService } from "../commenServices/DataService";
import { Review, ReviewText, Discount, customers } from "../domainModel/OperationalData";
import { decode } from "base64-arraybuffer";
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from "../domainModel/User"
import { PublicApiService } from '../commenServices/public.api.service';
import {DummyBizServiceService} from '../commenServices/dummyBizService.service';





export interface DiscountDialogInterface {
  startDate: Date;
  endDate: Date;
  discount: string;
  service?: BusinessService;
}

export interface priceChange {
  currentPrice: string;
  newPrice: string;
}

export interface reviewed {
  rating: string;
  reviewText: string;
}

export interface Customer {
  customer: BusinessService[];
}



@Component({
  selector: 'app-order-management',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.css']
})


export class IconsComponent implements OnInit {
  currentUser: User;
  nameOfCustomer: string[] = [];

  private companyOfUser: string;
  isDiscount: boolean = true;
  ownServices: BusinessService[] = [];
  boughtServices: BusinessService[] = [];

  purchasedBy: customers[] = []
  public companyName: string;
  price: string;
  discount: DiscountDialogInterface;
  Test: Discount;
  addedReview: reviewed;
  startDate: Date;
  endDate: Date;
  currentDiscount: string;
  rating: string;
  reviewText: string;
  writtenReview: ReviewText;

  dataSource;
  public businessServices: BusinessService[];
  public CustomerList: customers[];
  @ViewChild('frameMyServices') public modal: any;
  validatingForm: FormGroup;



  constructor(private dummyBizService: DummyBizServiceService,private apiService: PublicApiService, public dialog: MatDialog, private serviceSearch: ServiceSearchService, private http: HttpClient, private router: Router, private sanitizer: DomSanitizer, private sessionService: SessionService, private ds: DataService, private _snackBar: MatSnackBar) {
    this.validatingForm = new FormGroup({
      loginFormModalEmail: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      loginFormModalPassword: new FormControl('', Validators.required)

    });
    this.dataSource = new MatTableDataSource();
  }


  mapToByteArray(base64Content: string) {
    if (base64Content != null && base64Content.length > 30) {
      let content: ArrayBuffer;
      content = decode(base64Content);
      let objectURL = 'data:image/png;base64,' + base64Content;
      return this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }
    else {
      return null;
    }
  }

  getPurchaseDay(cust: string, list: customers[]): String {
    let item = list.find(i => i.customer.email === cust);
    let purchDate = new Date;
    purchDate.setTime(item.purchaseDate);
    return purchDate.toString();
  }


  totalReviewCount(review: Review) {
    const totalreview = review.count1Stars + review.count2Stars + review.count3Stars + review.count4Stars + review.count5Stars;
    return totalreview;
  }

  get loginFormModalEmail() {
    return this.validatingForm.get('loginFormModalEmail');
  }

  get loginFormModalPassword() {
    return this.validatingForm.get('loginFormModalPassword');
  }

  isLoggedIn() {
    if (this.sessionService.isLoggedIn == true) {
      return true;
    }
    else {
      return false;
    }
  }

  success() {
    if (this.loginFormModalEmail.value.length > 0) {
      if ((this.loginFormModalEmail.value.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")) && (this.loginFormModalPassword.value.length > 0)) {

        return true;
      }
      else {
        return false;
      }
    }
    return false;
  }

  ngAfterViewInit(): void {
    this.currentUser = this.sessionService.curUser;
    let status: boolean;
    status = this.sessionService.isLoggedIn
    if (status == false) {
      this.modal.disableClose = true;
      this.modal.show();
    }
  }

  goToDashBoard() {
    this.modal.hide();
    this.router.navigate(['/dashboard']);
  }

  findRightData(input: BusinessService) {
    if (input.operationalData != null) {

      for (var owner of input.operationalData.purchasedBy) {
        if (owner != null && owner.customer != null) {
          if (owner.customer.email == this.currentUser.email) {
            var d = new Date(owner.purchaseDate);
            return d;
          }
        }
      }
    }
    return "NA";
  }
  findRightDataStatus(input: BusinessService) {
    if (input.operationalData != null) {

      for (var owner of input.operationalData.purchasedBy) {
        if (owner != null && owner.customer != null) {
          if (owner.customer.email == this.currentUser.email) {
            var d = owner.isActive
            return d;
          }
        }
      }
    }
    return false;
  }

  startService(service: BusinessService) {
    // if already logged in, check if the current service is already purchased by the current user.
    // Currently the check is using businessService.operationalData.purchasedBy in the mongoDB, later it should call an API with token authorization check provided by the business service owner.
    // if the service is already purchased by the current user, then re-direct to the businessService.deployment.urlForInvocation (if the url is not given, using the dummy HLX URL http://51.91.150.98:12080/login);
    // otherwise, call the "purchase" API (currently a dummy service API is called) and show the message.

    this.dummyBizService.announceStartService(service).subscribe(
      (response) => {
        this.openURLinNewTab(
          this.getBusinessServiceURL(service)
        );
      })

  }

  openURLinNewTab(url: string) {

    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
    this._snackBar.open("Service has been started successfully!", 'close', { duration: 3000, horizontalPosition: 'center', verticalPosition: 'top' });
  }

  getBusinessServiceURL(service: BusinessService) {
    // return this.businessService.deployment.urlForInvocation if defined, otherwise return dummy HLX URL http://51.91.150.98:12080/login
    let dummyHLXServiceURL = "http://51.91.150.98:12080/login";

    let postfix:string;
    for(var c of service.operationalData.purchasedBy){
      if (c.customer.email === this.sessionService.curUser.email){
        postfix = c.accessToken;
      }
    }

    if (service.deployment.urlForInvocation) {
      return service.deployment.urlForInvocation + "login/"+postfix;
    } else {
      return dummyHLXServiceURL;
    }
  }

  confirmLogin() {
    this.apiService.login(this.validatingForm.get('loginFormModalEmail').value, this.validatingForm.get('loginFormModalPassword').value).subscribe(

      (response) => {
        let token = JSON.stringify(response);
        if (token.length > 10) {
          this.companyOfUser = this.loginFormModalEmail.value;

          this.sessionService.isLoggedIn = true;
          this.sessionService.oAuthToken = response.token;
          this.sessionService.emailOfUser = this.loginFormModalEmail.value;
          this.sessionService.companyOfUser = this.companyName;


          if (response.email != null){
            this.sessionService.emailOfUser = response.email;
          }
          if (response.firstName != null){

            var currentUser = new User();
            currentUser.email = response.email;
            currentUser.firstName = response.firstName;
            currentUser.lastName = response.lastName;
            currentUser.city = response.city;
            currentUser.address = response.address;
            currentUser.aboutme = response.aboutme;
            currentUser.country = response.country;
            currentUser.organization = response.organization;
            currentUser.postalcode = response.postalcode;
            currentUser.username = response.username;
            this.sessionService.companyOfUser = response.organization;

            this.currentUser = currentUser;
            this.sessionService.curUser = this.currentUser;
            this.sessionService.emailOfUser = response.email;
          }else {

            console.log("USE the old API and needs to ask for user details")
            this.apiService.getUserDetails(this.sessionService.emailOfUser).subscribe(
              (response) => {
                this.currentUser = response;
                this.sessionService.curUser = currentUser;
                this.sessionService.companyOfUser = response.organization;
              });
          }





          this.serviceSearch.searchForAllBusinessServicesYouPurchased(this.sessionService.emailOfUser).subscribe(
            (response) => {
              this.boughtServices = response.trickServices;
            },
            (error) => {
              console.error("Request denied");
              console.log(error)
            }
          );

          this.serviceSearch.searchForAllBusinessServicesYouOwn(this.sessionService.emailOfUser).subscribe(
            (response) => {
              this.ownServices = response.trickServices;
            },
            (error) => {
              console.error("request failed");
            }
          )
          this.modal.hide();
        } else {
          console.log("Sorry, your credentials are wrong or the srvice is not reachable");
        }
      }, exception => { alert("Wrong credentials") }
    );

  }


  convertNumberToDate(num: number): string {
    let newDate: Date = new Date();
    newDate.setTime(num);
    return newDate.toDateString();
  }






  ngOnInit() {



    if (this.sessionService.isLoggedIn) {

      this.serviceSearch.searchForAllBusinessServicesYouOwn(this.sessionService.emailOfUser).subscribe(
        (response) => {
          this.ownServices = response.trickServices;

        },
        (error) => {
          console.error("Request failed");
        }
      )
      this.serviceSearch.searchForAllBusinessServicesYouPurchased(this.sessionService.emailOfUser).subscribe(
        (response) => {
          this.boughtServices = response.trickServices;

        },
        (error) => {
          console.error("Request failed");
          console.log(error);
        }
      )
    }






  }


  testButton(button: string) {
    this._snackBar.open("I clicked " + button, 'close', { duration: 2000, horizontalPosition: 'center', verticalPosition: 'top' });
  }


  openDiscountDialog(service: BusinessService): void {

    const dialogRef = this.dialog.open(DialogDiscount, {
      data: { startDate: this.startDate, endDate: this.endDate, service: service }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.discount = result;
      this.Test = { 'discount': this.discount.discount, 'endDate': this.discount.endDate.valueOf(), 'startDate': this.discount.startDate.valueOf() };

      service.operationalData.discount = this.Test

      this.serviceSearch.updateBusinessServiceDirect(service).subscribe(
        (response) => {
          this._snackBar.open("Your discount was added", 'close', { duration: 2000, horizontalPosition: 'center', verticalPosition: 'top' });

        },
        (error) => {
          console.error("The request was not fulfilled");
          console.log(error);
        }
      )
    });
  }

  @HostListener('document:visibilitychange', ['$event'])
  appVisibility() {
    if (document.hidden) {
      //do whatever you want

      console.log("Hidden: " );

    }
    else {
      //do whatever you want


      console.log("SHOWN: " );
      this.serviceSearch.searchForAllBusinessServicesYouPurchased(this.sessionService.emailOfUser).subscribe(
        (response) => {
          this.boughtServices = response.trickServices;

        },
        (error) => {
          console.error("Request failed");
          console.log(error);
        }
      )
    }
  }



  openPriceDialog(service: BusinessService): void {
    const dialogRef = this.dialog.open(DialogPrice, {
      data: { currentPrice: service.operationalData.price, newPrice: this.price }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.price = result;
      service.operationalData.price = this.price;
      this.serviceSearch.updateBusinessServiceDirect(service).subscribe(
        (response) => {                           //next() callback
          this._snackBar.open("The price update was successful", 'close', { duration: 2000, horizontalPosition: 'center', verticalPosition: 'top' });
        },
        (error) => {//error() callback
          console.log(error);
          console.error('Request failed with error')

        },
        () => {                                   //complete() callback
          console.error('Request completed')      //This is actually not needed

        })

    });
  }
  openCustomerDialog(service: BusinessService): void {

    this.CustomerList = service.operationalData.purchasedBy;
    for (let i = 0; i <= service.operationalData.purchasedBy.length - 1; i++) {
      if (service.operationalData.purchasedBy[i].customer != null) {
        this.nameOfCustomer.push(service.operationalData.purchasedBy[i].customer.email);
      }
    }
    const dialogRef = this.dialog.open(CustomerDialog, {
      data: { customer: this.CustomerList }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.price = result;
    });


  }
  openReviewDialog(x: BusinessService): void {
    const dialogRef = this.dialog.open(ReviewDialog, {
      data: { rating: this.rating, reviewText: this.reviewText }
    });
    dialogRef.afterClosed().subscribe(result => {
      let rate = 0;

      this.addedReview = result;
      switch (this.addedReview.rating) {
        case "1":
          x.operationalData.review.count1Stars++;
          rate = 1;
          break;
        case "2":

          x.operationalData.review.count2Stars++;
          rate = 2;

          break;
        case "3":

          x.operationalData.review.count3Stars++;
          rate = 3;

          break;
        case "4":

          x.operationalData.review.count4Stars++;
          rate = 4;

          break;
        case "5":

          x.operationalData.review.count5Stars++;
          rate = 5;

          break;
        default:
          break;
      }

      let newReview = { 'rating': rate, 'reviewText': this.addedReview.reviewText };
      x.operationalData.reviewedText.push(newReview);
      this.serviceSearch.updateServiceDirectForStars(x).subscribe(
        (response) => {                           //next() callback
          this._snackBar.open("Your Review was successfully added", 'close', { duration: 2000, horizontalPosition: 'center', verticalPosition: 'top' });
        },
        (error) => {//error() callback
          console.log(error);
          console.error('Request failed with error')

        },
        () => {                                   //complete() callback
          console.error('Request completed')      //This is actually not needed

        })

    });


  }


}

@Component({
  selector: 'dialog-review',
  templateUrl: 'reviewDialog.html',
  styleUrls: ['./dialog.css']
})
export class ReviewDialog {
  constructor(public dialogRef: MatDialogRef<ReviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ReviewText) {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onOk(): void {

    this.dialogRef.close(this.data);
  }
}


@Component({
  selector: 'dialog-customer',
  templateUrl: 'customerDetails.html',
  styleUrls: ['./dialog.css']
})
export class CustomerDialog {
  CustomerList: string[][] = [];
  Object = Object;
  customer: customers[];
  emails: string[];
  accessTokens: string[];
  test: string[][];
  page: number = 1;

  getDate(x) {
    let date = new Date(x);
    return date.toDateString();
  }

  constructor(
    public dialogRef: MatDialogRef<CustomerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { customer: customers[] }
  ) {
    this.customer = data.customer;
  }
  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onOk(): void {
    this.dialogRef.close();
  }
  convertNumberToDate(num: number): string {
    let newDate: Date = new Date();
    newDate.setTime(num);
    return newDate.toDateString();
  }
}

@Component({
  selector: 'dialog-discount',
  templateUrl: 'discountDialog.html',
  styleUrls: ['./dialog.css']
})
export class DialogDiscount {
  constructor(
    public dialogRef: MatDialogRef<DialogDiscount>,
    @Inject(MAT_DIALOG_DATA) public data: DiscountDialogInterface) {
  }
  convertNumberToDate(num: number): String {
    let newDate: Date = new Date();
    newDate.setTime(num);
    return newDate.toDateString();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOk(): void {

    this.dialogRef.close(this.data);
  }

  deleteDiscount(): void {
    this.data.discount = "0";
    this.data.endDate = new Date();
    this.data.startDate = new Date();
    this.dialogRef.close(this.data);
  }

}



@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialogPrice.html',
  styleUrls: ['./dialog.css']
})
export class DialogPrice {
  price: string;
  constructor(
    public dialogRef: MatDialogRef<DialogPrice>,
    @Inject(MAT_DIALOG_DATA) public data: priceChange) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOk(): void {
    this.price = this.data.newPrice;
    this.dialogRef.close(this.price);
  }




}



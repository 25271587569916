export class BasicInformation {
  name: string;

  constructor() {

    this.shortDescription = "";
    this.longDescritpion = "";
    this.name = "";


  }


  icon: string;
  image: string;
  longDescritpion: string;
  shortDescription: string;
  creationDate: number;

}
